/* eslint-disable react-hooks/exhaustive-deps */
import VConfirm from "components/v-confirm";
import VDialog from "components/v-dialog";
import StatusEditorCell from "components/v-edit-cell/StatusEditorCell";
import debounce from "lodash.debounce";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { Image } from "primereact/image";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { TreeTable } from "primereact/treetable";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Service from "services/users";
import { formatLocalTime, getUrlImage, showToast } from "utils/common";
import { USER_STATUS } from "utils/enum";
import Detail from "../../app/users/components/Details";
import CategoryService from "services/categories";

const List = () => {
  const params: any = useParams();
  const accountType = params.type;

  const [dataList, setDataList] = useState([]);
  const [data, setData] = useState([]);
  const [detail, setDetail] = useState(null);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
    parent_id: null,
    status: null,
  });
  const [lazyParams, setLazyParams]: any = useState({
    limit: 50,
    page: 1,
    sortField: "createdAt",
    sortOrder: -1,
    type: null,
  });

  const defaultOptions = {
    options: null,
    rowData: null,
    value: null,
  };
  const [totalRecords, setTotalRecords] = useState(0);
  const [users, setUsers] = useState([]);

  const [keySearch, setKeySearch] = useState("");
  const [optionSelect, setOptionSelect] = useState(defaultOptions);

  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);
  const refDialogDelete = useRef(null);
  const refDialogDisable = useRef(null);

  const refDetail = useRef(null);

  const [isApplyFromAccountType, applyFromAccountType] = useState(false);

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  useEffect(() => {
    if (!!lazyParams?.type) {
      getUsers();
    }
    // getCategoryFilters();
  }, [lazyParams]);

  useEffect(() => {
    if (accountType) {
      setLazyParams((prev) => ({
        ...prev,
        type: accountType,
      }));
      getAccountType();
    }
  }, [accountType]);

  const getUsers = async () => {
    const res: any = await Service.getRootUserByType({
      params: { type: params?.type },
      query: {
        page: lazyParams?.page || 1,
        limit: lazyParams?.limit || 50,
      },
    });
    setUsers(res?.docs?.map((e) => ({ ...e, key: e?._id })));
    setTotalRecords(res?.totalDocs);
  };

  const getAccountType = async () => {
    const res: any = await CategoryService.getByKey({
      params: accountType
    });
    if (res && res?.additionalDataForm?.is_add_to_user) {
      applyFromAccountType(true);
    }
  };

  const loadData = async () => {
    getUsers();
  };

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = {
      ...lazyParams,
      ...event,
      page: event.page + 1,
      limit: event?.rows,
    };
    setLazyParams(_lazyParams);
  };

  // const onSort = (event) => {
  //   let _lazyParams = { ...lazyParams, ...event };
  //   setLazyParams(_lazyParams);
  // };

  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  //CREATE OR NEW
  const openNew = () => {
    setDetail(null);
    refDialogDetail.current.show();
  };

  const editMenuCategory = (data) => {
    setDetail({ ...data, key: data.keyVal });
    refDialogDetail.current.show();
  };

  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };

  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };

  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  // DELETE
  const confirmDelete = (product) => {
    setDetail(product);
    refDialogDelete.current.show();
  };
  const handleDelete = async () => {
    try {
      await Service.deleteUser({
        params: {
          id: detail._id,
        },
      });
      const _data = data.filter((val) => val._id !== detail._id);
      setData(_data);
      refDialogDelete.current.close();
      setDetail(null);
      showToast(toast, "success", "Category Deleted");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  const onChangeStatus = async (options, rowData, value) => {
    if (options?.field === "status" && value === "disable") {
      setOptionSelect({ options, rowData, value });
      refDialogDisable.current.show();
      return;
    }

    let updateRow = dataList?.find((e) => e?._id === rowData?._id);
    if (updateRow) {
      updateRow.status = value;
      await Service.updateUser({
        params: { id: updateRow._id },
        body: {
          ...updateRow,
        },
      });
    }

    // loadData();
    showToast(toast, "success", options?.field + " updated!");
  };

  const handleDisable = async () => {
    const { options, rowData, value } = optionSelect;
    let updateRow = dataList?.find((e) => e?._id === rowData?._id);
    if (updateRow) {
      updateRow.status = value;
      await Service.updateUser({
        params: { id: updateRow._id },
        body: {
          ...updateRow,
        },
      });
    }

    refDialogDisable.current.close();
    setOptionSelect(defaultOptions);
    loadData();
    showToast(toast, "success", options?.field + " updated!");
  };

  const onFiltergroup = async (e) => {
    if (e?.filter) {
      const res: any = await Service.search({
        query: {
          account_type: params?.type,
          search: e?.filter,
          page: 1,
          limit: 10,
        },
      });
      if (res && res?.docs) {
        const newData = res?.docs?.filter(
          (el) => !users?.find((item) => item?._id === el?._id)
        );
        setUsers((users || []).concat(newData));
        setTotalRecords(res?.totalDocs);
      }
    }
  };

  const onSearchFilterGroup = debounce(onFiltergroup, 700);

  // const orderEditor = (options) => {
  //   return <NumberEditor options={options} onChange={onChangeOrder} />;
  // };

  // const statusEditor = (options) => {
  //   return <StatusEditor options={options} onChange={onChangeStatus} />;
  // };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />

          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            // onClick={confirmDeleteSelected}
            // disabled={!selectedProducts || !selectedProducts.length}
            disabled={true}
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          name="Import"
          chooseLabel="Import"
          disabled
          className="mr-2 inline-block"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          disabled
          className="p-button-help"
          // onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const addChild = (data) => {
    let childData = { parent_id: data._id, group: params?.group };
    setDetail({ ...childData });
    refDialogDetail.current.show();
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        {/* {!rowData.leaf && ( */}
        <Button
          icon="pi pi-plus"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => addChild(rowData)}
        />
        {/* // )} */}
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => editMenuCategory(rowData)}
        />

        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-sm p-button-warning"
          onClick={() => confirmDelete(rowData)}
        />
      </div>
    );
  };

  const getByParent = (textUsers, parent) => {
    if (textUsers) {
      let mapRes = textUsers
        .filter((e) => e.parent_id === parent)
        .map((e) => ({ ...e, children: getByParent(textUsers, e._id) }));
      return mapRes;
    } else {
      return [];
    }
  };

  const onExpand = async (event) => {
    if (!event.node.parent_id) {
      let children = event.node.children;
      // todo find all children of children
      const res: any = await Service.getByTextSearch({
        params: event.node._id,
      });

      if (res && res?.docs?.length > 0) {
        children = children.map((e) => ({
          ...e,
          children: getByParent(res?.docs, e._id),
        }));

        let _nodes = users.map((e) => {
          if (e.key === event.node.key) {
            e = { ...e, children: children };
          }

          return e;
        });

        setUsers(_nodes);

        // event.node.children = children;
      }
    }
  };

  const onSearchByKey = (e: any) => {
    if (e.key === "Enter") {
      handleFilterTree();
    }
  };

  const handleFilterTree = () => {
    if (keySearch?.length > 0) {
      const filterDataList = dataList?.filter((e) =>
        e?.name?.toUpperCase()?.includes(keySearch?.toUpperCase())
      );
      setData(filterDataList);
    } else {
      setData(dataList);
    }
  };

  const header = (
    <Panel header="Filter" toggleable collapsed={false}>
      <div className="grid my-2 align-items-center">
        <div className="col-12 md:col-6">
          <span className="block m-1 p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              value={keySearch}
              onInput={(e: any) => setKeySearch(e.target.value)}
              onKeyDown={onSearchByKey}
              placeholder="Search..."
              className="w-full"
            />
          </span>
        </div>
        <div className="col-12 md:col-6">
          <Dropdown
            value={globalFilter.parent_id}
            options={users}
            optionLabel="full_name"
            optionValue="_id"
            onChange={(e) => onSearch("parent_id", e.value)}
            showClear
            filter
            onFilter={(e) => onSearchFilterGroup(e)}
            placeholder="Parent"
            className="w-full"
          />
        </div>
        <div className="col-12 md:col-6">
          <Dropdown
            value={globalFilter.status}
            options={Object.keys(USER_STATUS).map((k) => ({
              label: k,
              value: USER_STATUS[k],
            }))}
            onChange={(e: any) => onSearch("status", e.value)}
            optionLabel="label"
            placeholder="Status"
            className={"w-full"}
            showClear
          />
        </div>
      </div>
    </Panel>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <TreeTable
            ref={dt}
            value={users}
            header={header}
            onExpand={onExpand}
            paginator
            rowsPerPageOptions={[10, 25, 50]}
            emptyMessage="No data found."
            lazy
            first={lazyParams.page}
            rows={lazyParams.limit}
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
            // onSort={onSort}
            totalRecords={totalRecords}
          >
            <Column
              // frozen
              // alignFrozen="left"
              body={actionBodyTemplate}
              style={{ flexGrow: 1, flexBasis: "260px" }}
            ></Column>
            <Column
              field="full_name"
              header="Full Name"
              expander
              style={{ flexGrow: 1, flexBasis: "180px" }}
              body={(rowData) => <span>{rowData.full_name}</span>}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "30px" }}
              header="Avatar"
              body={(rowData) => {
                return (
                  <Image
                    src={getUrlImage(rowData.avatar)}
                    alt={rowData.full_name}
                    width="50"
                    height="50"
                    preview
                  />
                );
              }}
            ></Column>
            <Column
              // editor={statusEditor}
              field="status"
              header="Status"
              style={{ flexGrow: 1, flexBasis: "100px" }}
              // body={(rowData) => (
              //   <span
              //     className={`v-badge status-${rowData.status?.toLowerCase()}`}
              //   >
              //     {rowData.status}
              //   </span>
              // )}
              body={(rowData, options) => (
                <StatusEditorCell
                  value={rowData.status}
                  options={options}
                  rowData={rowData}
                  onChange={onChangeStatus}
                  status={USER_STATUS}
                />
              )}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "100px" }}
              field="createdAt"
              header="Created At"
              sortable
              body={(rowData) => formatLocalTime(rowData.createdAt)}
            ></Column>

            <Column
              style={{ flexGrow: 1, flexBasis: "100px" }}
              field="updatedAt"
              header="Updated At"
              sortable
              body={(rowData) => formatLocalTime(rowData.updatedAt)}
            ></Column>
          </TreeTable>

          <VDialog
            ref={refDialogDetail}
            header="Details"
            onSubmit={onSumitDialogDetail}
          >
            <Detail
              ref={refDetail}
              data={detail}
              toast={toast}
              reload={loadData}
              setLoading={setLoadingSaveDetail}
              onCancel={onCancelDialogDetail}
              isApplyFromAccountType={isApplyFromAccountType}
            />
          </VDialog>

          <VConfirm
            ref={refDialogDisable}
            isDisable={true}
            onConfirm={handleDisable}
          />

          <VConfirm ref={refDialogDelete} onConfirm={handleDelete} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(List, comparisonFn);
