import VGroupRadio from "components/v-group-radios";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import { RadioButton } from "primereact/radiobutton";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import UserService from "services/users";
import { showToast } from "utils/common";
import { COMMON_STATUS } from "utils/enum";
import EditBackground from "./EditBackground";
import EditCover from "./EditCover";
import { v4 as uuidv4 } from "uuid";
import EditBanners from "./edit-banners";

const DEFAULT_DATA = {
  covers: {
    type: "slide" || "youtube" || "link",
    url: "",
    slide: [],
    banners: {
      background_color: "",
      text_color: "",
      content: [],
    },
  },
  background: {
    type: "slide" || "youtube" || "link",
    url: "",
    slide: [],
  },
  banners: {
    top: {},
    right: {},
    left: {},
    popup_top: {},
    popup_center: {},
    popup_bottom: {},
  },
};

const RADIO_OPTIONS = [
  {
    label: "Top",
    value: "top",
  },
  {
    label: "Left",
    value: "left",
  },
  {
    label: "Right",
    value: "right",
  },
  {
    label: "Popup center",
    value: "popup_center",
  },
  {
    label: "Popup top",
    value: "popup_top",
  },
  {
    label: "Popup bottom",
    value: "popup_bottom",
  },
];

type IProps = {
  userId?: string;
  toast?: any;
  loading?: boolean;
  setLoading?: any;
  reload?: any;
  onCancel?: any;
};

const EditProfileBanners = (props: IProps, ref: any) => {
  const { userId, toast, loading, setLoading, reload, onCancel } = props;

  const [userData, setUserData] = useState(null);
  const [details, setDetails] = useState(DEFAULT_DATA);
  const [bannersData, setBannersData] = useState(details?.banners);
  const [position, setPosition] = useState("top");

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (userId) {
      getUserById();
    }
  }, [userId]);

  const getUserById = async () => {
    try {
      const res: any = await UserService.getUserById({
        params: {
          id: userId,
        },
      });
      if (res) {
        setUserData(res.user);
        if (res.user && res.user.profile) {
          setDetails((prev) => ({
            ...prev,
            covers: {
              ...prev.covers,
              ...res.user.profile.covers,
            },
            background: {
              ...prev.background,
              ...res.user.profile.background,
            },
            banners: {
              ...prev.banners,
              ...res.user.profile.banners,
            },
          }));
          setBannersData((prev) => ({
            ...prev,
            ...res.user.profile.banners,
          }));
        }
      }
    } catch (error) {}
  };

  const handleChangePosition = (value: string) => {
    setPosition(value);
  };

  const onBannersChange = (value: any, name: string) => {
    setBannersData((prev) => ({
      ...prev,
      [position]: {
        ...prev[position],
        [name]: value,
      },
    }));
  };

  const submit = async () => {
    try {
      setLoading(true);
      await UserService.updateUser({
        params: {
          id: userId,
        },
        body: {
          ...userData,
          profile: {
            ...userData?.profile,
            ...details,
            banners: bannersData,
          },
        },
      });

      setLoading(false);
      showToast(toast, "success", "User saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <>
      <Panel header="Cover" toggleable collapsed className="my-2">
        <EditCover data={details.covers} setData={setDetails} />
      </Panel>
      <Panel header="Background" toggleable collapsed className="my-2">
        <EditBackground data={details.background} setData={setDetails} />
      </Panel>
      <Panel header="Banners" toggleable className="my-2">
        <VGroupRadio
          checked={position}
          options={RADIO_OPTIONS}
          onChange={(value) => handleChangePosition(value)}
        />
        <EditBanners
          data={bannersData[position]}
          setData={setBannersData}
          onChange={onBannersChange}
          position={position}
        />
      </Panel>
    </>
  );
};

export default forwardRef(EditProfileBanners);
